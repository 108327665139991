.buttonsContainer{
    padding-top: 1rem;
    @include media ('<=medium'){
        max-width: 100%;
        width: 100%;
        display: block;
        float: none;
    }
    button, .button{
        width: 100%;
        text-align: center;
        min-width: 200px;
        background-color: $color-black;
        color: #fff;
        height: 45px;
        position: relative;
        border: none;
        border-radius: 0;
        i {
            margin-right: 0.5rem;
        }
        &:before, &:after {
            content: '';
            position: absolute;
            opacity: 0;
            width: 0%;
            height: 0%;
            top: 0;
            left: 0;
            transition: opacity 0.4s;
        }
        &.loading {
            &:before {
                width: 100%;
                height: 100%;
                background-color: #848484;
                opacity: 0.7;
            }
            &:after {
                width: 100%;
                height: 100%;
                background-image: url('../img/bars.svg');
                background-size: contain;
                background-size: 30px 30px;
                background-repeat: no-repeat;
                background-position: 50%;
                opacity: 1;
            }
        }
    }
    .fa-plus-square, .fa-map-marker-alt{
        margin: 0px 8px;
        font-size: 18px;
        vertical-align: middle;
    }
    .fa-plus-square > path{
        fill: white!important;
    }
    .fa-map-marker-alt > path{
        fill: $color-blue!important;
    }
    .blueButton, .addOffer{
        background-color: $color-deep-blue;
        color: #fff;
        margin: 4px 0px;
    }
    .orangeButton{
        background-color: $color-orange;
        color: #fff;
        margin: 4px 0px;
    }
    .searchOffer{
        background-color:#fff;
        color: $color-blue;
        border: 1px solid $color-blue;
    }
    #subButtons{
        color: #fff;
        padding: 0px 12px;
        margin-top: -25px;
        margin-bottom: 32px;
        ul{
            padding: 32px 32px 16px 76px;
            background-color: #367bf0;
            border-radius: 12px;
            @include media ('<=small'){
                padding: 32px 32px 16px 32px;
            }
        }
        li{
            margin: 8px 0px;
            list-style-type: disc;
        }
      
    }
    
}
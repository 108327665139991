.purplebutton {
  color: #ffffff;
  background-color: #3d2e55;
  font-size: 16px;
  font-weight: 600;
  border-radius: 25px;
}

.socials {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 40px;
  height: 100px;
  background: #f9f9f9;
}

.socials.footer {
  background: none;
  width: fit-content;
  margin: 0 auto;
  height: 40px;
}

.socials span {
  display: block;
  margin-bottom: 10px;
  color: #333;
  text-align: center;
}

.socials ul {
  display: flex;
  justify-content: center;
}

.socials ul li {
  margin-right: 10px;
}

.socials ul li:last-child {
  margin-right: 0;
}

.socials ul li svg {
  font-size: 25px;
}


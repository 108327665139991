@import '~include-media/dist/_include-media';
@import '../../Settings.scss';

@import '~bulma/sass/utilities/_all.sass';
@import '~bulma/sass/base/_all.sass';

@import "~bulma/sass/grid/columns.sass";
@import "~bulma/sass/elements/container.sass";
@import "~bulma/sass/elements/content.sass";

@import '~bulma/sass/form/_all.sass';
@import '../../Buttons.scss';
:local
fieldset.content:not(:last-of-type) {
  padding-bottom: 10px;
}

.page-contact{
  .error{
    color: #ff0000;
    font-size: 12px;
  }
}

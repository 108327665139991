.purplebutton {
  color: #ffffff;
  background-color: #3d2e55;
  font-size: 16px;
  font-weight: 600;
  border-radius: 25px;
}

nav.navBar.active ul {
  display: flex;
}

nav.navBar.active .toggle:after {
  content: '\25b2';
}

nav.navBar ul {
  display: flex;
}

@media (min-width: 768px) {
  nav.navBar ul.sections {
    position: relative;
  }

  nav.navBar ul.sections.withContents {
    margin-bottom: 60px;
  }

  nav.navBar ul.sections > li > a {
    transition: 0.2s;
  }

  nav.navBar ul.sections > li > a:hover,
  nav.navBar ul.sections > li > a.active {
    height: 70px;
    margin-top: -10px;
  }

  nav.navBar ul.contents {
    position: absolute;
    left: 0;
    right: 0;
    top: 60px;
  }

  nav.navBar ul.contents a:after {
    content: '';
    position: absolute;
    right: 0;
    border-right: 1px #ffffff solid;
    height: 30px;
    opacity: 0.5;
  }

  nav.navBar ul.contents li:last-child a:after {
    border-right: none;
  }
}

@media (max-width: 767px) {
  nav.navBar ul {
    display: none;
    flex-direction: column;
  }

  nav.navBar ul.sections > li > a {
    font-size: 16px;
  }

  nav.navBar ul.contents {
    position: relative;
    padding: 20px 0 10px;
  }

  nav.navBar ul.contents:before {
    content: '';
    position: absolute;
    top: 0;
    left: 60%;
    margin-left: -30%;
    border-top: 1px #ffffff solid;
    width: 40%;
    opacity: 0.5;
  }
}

@media (max-width: 767px) and (max-width: 567px) {
  nav.navBar ul.contents:before {
    left: 20%;
    margin-left: -10%;
    width: 80%;
  }
}

@media (max-width: 767px) {
  nav.navBar ul.contents a {
    height: auto;
    margin-bottom: 10px;
  }
}

nav.navBar ul li {
  flex: 1;
}

nav.navBar ul li a {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  color: #ffffff;
  text-decoration: none;
  text-align: center;
}

nav.navBar ul li a.articles {
  background: #333;
}

nav.navBar ul li a .icon {
  max-width: 30px;
  max-height: 30px;
  margin-right: 10px;
}

@media (max-width: 1023px) {
  nav.navBar ul li a .icon {
    margin-right: 15px;
  }
}

nav.navBar .toggle {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  color: #333;
  font-size: 12px;
  text-transform: uppercase;
  cursor: pointer;
  background-color: #f9f9f9;
}

nav.navBar .toggle:after {
  content: '\25bc';
  margin-left: 10px;
  font-size: 9px;
  vertical-align: middle;
}

@media (min-width: 768px) {
  nav.navBar .toggle {
    display: none;
  }
}

nav.navBar .gifLoader {
  position: absolute;
  width: 60px;
  opacity: 0;
  display: none;
}

nav.navBar .loading {
  opacity: 0.7;
}

nav.navBar .loading .gifLoader {
  display: block;
  animation: tileLoading 1s infinite;
  animation-delay: 0.3s;
}

@keyframes tileLoading {
  from {
    opacity: 0.6;
  }

  to {
    opacity: 0.6;
  }
}


@import '~include-media/dist/_include-media';
@import '../../Settings';


.contentItem {
    width: 300px;
    padding: 20px;
    img{
        width: 100%;
    }
}
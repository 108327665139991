@import '~include-media/dist/_include-media';
@import '../../Settings';

nav.navBar {
    &.active {
        ul {
            display: flex;
        }
        .toggle {
            &:after {
                content: '\25b2';
            }
        }
    }
    ul {
        display: flex;
        @include media('>=medium') {
            &.sections {
                position: relative;
                &.withContents {
                    margin-bottom: 60px;
                }
                > li > a {
                    transition: $animation-duration;
                    &:hover,
                    &.active {
                        height: 70px;
                        margin-top: -10px;
                    }
                }
            }
            &.contents {
                position: absolute;
                left: 0;
                right: 0;
                top: 60px;
                a:after {
                    content: '';
                    position: absolute;
                    right: 0;
                    border-right: 1px #ffffff solid;
                    height: 30px;
                    opacity: 0.5;
                }
                li:last-child {
                    a:after {
                        border-right: none;
                    }
                }
            }
        }
        @include media('<medium') {
            display: none;
            flex-direction: column;
            &.sections {
                > li > a {
                    font-size: 16px;
                }
            }
            &.contents {
                position: relative;
                padding: 20px 0 10px;
                &:before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 60%;
                    margin-left: -30%;
                    border-top: 1px #ffffff solid;
                    width: 40%;
                    opacity: 0.5;
                    @include media('<small') {
                        left: 20%;
                        margin-left: -10%;
                        width: 80%;
                    }
                }
                a {
                    height: auto;
                    margin-bottom: 10px;
                }
            }
        }
        li {
            flex: 1;
            a {
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 60px;
                color: #ffffff;
                text-decoration: none;
                text-align: center;
                &.articles {
                    background: $color-black;
                }
                .icon {
                    max-width: 30px;
                    max-height: 30px;
                    margin-right: 10px;
                    @include media('<large') {
                        margin-right: 15px;
                    }
                }
            }
        }
    }
    .toggle {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 50px;
        color: $color-black;
        font-size: 12px;
        text-transform: uppercase;
        cursor: pointer;
        background-color: $color-smoke;
        &:after {
            content: '\25bc';
            margin-left: 10px;
            font-size: 9px;
            vertical-align: middle;
        }
        @include media('>=medium') {
            display: none;
        }
    }
    .gifLoader{
        position: absolute;
        width: 60px;
        opacity: 0;
        display: none;
    }
    .loading{
        opacity: 0.7;
        .gifLoader{
            display: block;
            animation: tileLoading 1s infinite;
            animation-delay: 0.3s;
        }
    }
    @keyframes tileLoading {
        from { opacity: 0.6; }
        to { opacity: 0.6; }
    }
}

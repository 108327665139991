@import '~include-media/dist/_include-media';
@import '../../Settings';

.partners-list {
  text-align: center;
  padding: 0 40px;
  @include media('<medium') {
    padding: 0 20px;
  }
  @include media('<small') {
    flex-direction: column;
    align-items: center;
  }
  img {
    max-width: 165px;
    max-height: 100px;
    padding-left: 20px;
    padding-right: 20px;
  }
}
@charset "utf-8";
@import '../../../Settings';

@import '../../../../../node_modules/bulma/sass/utilities/all';
@import '../../../../../node_modules/bulma/sass/base/all';

@import "../../../../../node_modules/bulma/sass/elements/button";
@import "../../../../../node_modules/bulma/sass/elements/container";
@import "../../../../../node_modules/bulma/sass/elements/box";
@import '../../../../../node_modules/bulma/sass/components/modal';

html.modal-open {
  overflow: hidden;
}

.modal h4 {
  position: relative;
  padding-bottom: 20px;
  margin-bottom: 40px;
  font-size: 28px;
  font-weight: 700;
  color: $color-black;
  &:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    display: block;
    width: 100px;
    border-bottom: 1px $color-black solid;
  }
}

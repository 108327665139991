@import '~include-media/dist/_include-media';
@import '../../Settings';

#info-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px;
    text-align: center;
    background: #f5f5f5;
    @include media('<medium') {
        padding: 40px 20px;
    }
    h1 {
        font-size: 40px;
        transition: $animation-duration;
        @include media('<medium') {
            font-size: 36px;
        }
    }
    h2 {
        font-size: 34px;
        transition: $animation-duration;
        @include media('<medium') {
            font-size: 30px;
        }
    }
    h3 {
        font-size: 28px;
        transition: $animation-duration;
        @include media('<medium') {
            font-size: 24px;
        }
    }
    h4 {
        font-size: 22px;
        transition: $animation-duration;
        @include media('<medium') {
            font-size: 18px;
        }
    }
    h5 {
        font-size: 16px;
        transition: $animation-duration;
        @include media('<medium') {
            font-size: 14px;
        }
    }
    p {
        max-width: map-get($breakpoints, large);
    }
    a {
        display: block;
        padding: 13px 40px;
        margin-top: 40px;
        color: #ffffff;
        text-decoration: none;
        background-color: $color-red;
        border-radius: 20px;
        transition: $animation-duration;
        &:hover {
            transform: scale(1.1);
        }
    }
}
@import '~include-media/dist/_include-media';
@import '../../../Settings';

.avatar-input {
  .image {
    position: relative;
    margin-left: auto;
    margin-right: auto;

    @include media('>medium') {
      height: 64px;
      width: 64px;
    }

    input[type="file"] {
      border: 0;
      clip: rect(0, 0, 0, 0);
      height: 1px;
      overflow: hidden;
      padding: 0;
      position: absolute !important;
      white-space: nowrap;
      width: 1px;
    }

    label {
      position: absolute;
      bottom: -0.5rem;
      right: -0.5rem;
      cursor: pointer;
      padding: 5px;
      text-shadow: 0 0 3px #333;
      opacity: 0.8;
      color: #fff;

      &:hover {
        opacity: 1;
      }
    }
  }
}
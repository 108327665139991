.purplebutton {
  color: #ffffff;
  background-color: #3d2e55;
  font-size: 16px;
  font-weight: 600;
  border-radius: 25px;
}

.articleItem {
  width: 300px;
  padding: 20px;
}

.articleItem img {
  width: 100%;
}


.purplebutton {
  color: #ffffff;
  background-color: #3d2e55;
  font-size: 16px;
  font-weight: 600;
  border-radius: 25px;
}

.layout .mainColumn .post-card {
  position: relative;
}

.layout .mainColumn .post-card:hover .image {
  transform: scale(1.1);
}

.layout .mainColumn .post-card .section {
  position: relative;
  z-index: 1;
}

.layout .mainColumn .post-card .actions {
  position: absolute;
  top: 1.25rem;
  right: 0.75rem;
  z-index: 30;
}

.layout .mainColumn .post-card .actions .tags a.tag:hover {
  text-decoration: none;
}

.layout .mainColumn .post-card .thumb {
  position: relative;
  width: 100%;
  height: 200px;
  margin: -15px 0 0;
  overflow: hidden;
  z-index: 0;
}

.layout .mainColumn .post-card .thumb .image {
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  transition: 0.2s;
  background-repeat: no-repeat;
  background-image: url("/assets/fallback-background.jpg");
}

.layout .mainColumn .post-card h2,
.layout .mainColumn .post-card p {
  color: #333;
}

.layout .mainColumn .post-card h2 {
  font-size: 1.2rem;
  font-weight: bold;
  margin: 0;
  padding: 0;
}


@import '../../../../../node_modules/include-media/dist/include-media';
@import '../../../Settings';

.header {
    display: flex;
    height: $height-header;
    z-index: 1;

    @include media('<large') {
        .socials {
            display: none;
        }
    }

    &.active {
        @include media('<large') {
            margin-bottom: 100px;
            .right {
                .menu {
                    display: block;
                    padding: 0;
                }
            }
        }
        @include media('<medium') {
            margin-bottom: 200px;
        }
    }
    .left,
    .right {
        display: flex;
        align-items: center;
    }
    .left {
        max-width: 45%;
        padding-left: 40px;
        @include media('<large') {
            padding-left: 20px;
            max-width: 100%;
        }
        .logo {
            height: 100%;
            margin-right: 40px;
            display: flex;
            align-items: center;
            @include media('<large') {
                margin-right: 20px;
            }

            img {
                max-height: 60px;
                //max-width: 50%;

                //position: relative;
                //top: 50%;
                //transform: translateY(-50%);
            }
        }
        .category {
            height: 40px;
            padding: 10px;
            border-radius: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    .right {
        flex: 1;
        justify-content: flex-end;
        .menu {
            display: flex;
            align-items: center;
            margin-right: 40px;
            @include media('<large') {
                display: none;
                justify-content: flex-end;
                position: absolute;
                top: 100px;
                right: 0;
                left: 0;
                min-height: $height-header;
                padding: 0 40px;
                margin-right: auto;
                background: $color-red;
                z-index: 1;
            }
            li {
                text-align: center;
                margin-right: 40px;
                &:last-of-type {
                    margin-right: 0;
                }
                @include media('<large') {
                    float: left;
                    margin: 0;
                    width: 25%;
                    height: 99px;
                    padding: 2rem 0;

                    &.double {
                        width: 50%;
                    }
                }
                @include media('<medium') {
                    width: 50%;

                    &.double {
                        width: 100%;
                    }
                }

                a, b {color: #fff;}
                a {
                    @include media('>=large') {
                        color: $color-black;
                        transition: $animation-duration;
                        &:hover {
                            color: $color-red;
                            svg {
                                color: $color-red;
                            }
                        }
                    }
                    svg {
                        display: block;
                        margin: 0 auto 10px;
                        font-size: 28px;
                        color: #ffffff;
                        @include media('>=large') {
                            color: $color-black;
                            transition: $animation-duration;
                        }
                    }
                }
            }
        }
        .toggleMenu {
            margin-right: 40px;
            color: $color-red;
            text-decoration: none;
            text-align: center;
            cursor: pointer;
            @include media('>=large') {
                display: none;
            }
            @include media('<large') {
                margin-right: 20px;
            }
            svg {
                display: block;
                margin: 0 auto 10px;
                font-size: 28px;
                transition: $animation-duration;
            }
        }
    }
    .gifLoader{
        position: absolute;
        display: none;
        width: 60px;
        opacity: 0;
    }
    .loading {
        opacity: 0.7;
        .gifLoader{
            display: block;
            animation: tileLoading 1s infinite;
            animation-delay: 0.3s;
        }
    }
    @keyframes tileLoading {
        from { opacity: 0.6; }
        to { opacity: 0.6; }
    }
}

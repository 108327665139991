@import '~include-media/dist/_include-media';
@import '../../../Settings.scss';

@import '~bulma/sass/utilities/_all.sass';
@import '~bulma/sass/base/_all.sass';
@import '~bulma/sass/grid/columns.sass';
@import '~bulma/sass/elements/tag.sass';
@import '~bulma/sass/components/dropdown.sass';


.post-item {
  .key-infos {
    order: 2;
  }

  .post-content {
    order: 1;

    .description {
      margin-bottom: 2rem;

      b {
        font-weight: bold;
      }
      i {
        font-style: italic;
      }
    }

    @include media('<small') {
      .column.is-12-tablet {
        border-bottom: 1px solid rgba(0, 0, 0, 0.15);

        &:nth-child(odd) {
          border-right: 1px solid rgba(0, 0, 0, 0.15);
        }
      }
    }
    h5 {
      font-size: 1rem;
      font-weight: bold;

      @include media('<small') {
        padding-bottom: 0;
      }
    }
  }

  .owner-infos {
    order: 3;
    border-top: 1px solid rgba(0, 0, 0, 0.15);

    @include media('<small') {
      border-top: 0;
      margin-top: 2rem;
    }

    .contact {
      display: flex;
      align-items: center;
      justify-content: center;

      > a {
        display: block;
      }
    }
  }

  .dates {
    margin: 1rem 0;
    span {
      font-weight: bold;
      min-width: 8.5rem;
      float: left;
    }
  }
}
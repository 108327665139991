@import '~include-media/dist/_include-media';
@import '../../../Settings.scss';

.createaccountbody {
    span.error{
        color: #ff0000
    }
}
.accountbanner{
    background-color: $color-purple;
    color: #ffffff;
    padding: 25px;
    display: flex;
    flex-wrap: wrap;
    margin-left: calc(0% - 40px);
    width: calc(100% + 30px);
    box-sizing: content-box;
    i {
        margin-right: 15px;
    }
    @include media('<medium') {
        margin-left: calc(0% - 20px);
        width: calc(98%);
    }
    h2{
        text-transform: uppercase;
        font-size: 22px;
        font-weight: 600;
    }
    span{
        font-weight: 400;
        font-size: 16px;
        margin-left: 6px;
    }
    .fa-user{
        margin-right: 6px;
    }
}

.purplebutton {
  color: #ffffff;
  background-color: #3d2e55;
  font-size: 16px;
  font-weight: 600;
  border-radius: 25px;
}

#info-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  text-align: center;
  background: #f5f5f5;
}

@media (max-width: 767px) {
  #info-box {
    padding: 40px 20px;
  }
}

#info-box h1 {
  font-size: 40px;
  transition: 0.2s;
}

@media (max-width: 767px) {
  #info-box h1 {
    font-size: 36px;
  }
}

#info-box h2 {
  font-size: 34px;
  transition: 0.2s;
}

@media (max-width: 767px) {
  #info-box h2 {
    font-size: 30px;
  }
}

#info-box h3 {
  font-size: 28px;
  transition: 0.2s;
}

@media (max-width: 767px) {
  #info-box h3 {
    font-size: 24px;
  }
}

#info-box h4 {
  font-size: 22px;
  transition: 0.2s;
}

@media (max-width: 767px) {
  #info-box h4 {
    font-size: 18px;
  }
}

#info-box h5 {
  font-size: 16px;
  transition: 0.2s;
}

@media (max-width: 767px) {
  #info-box h5 {
    font-size: 14px;
  }
}

#info-box p {
  max-width: 1024px;
}

#info-box a {
  display: block;
  padding: 13px 40px;
  margin-top: 40px;
  color: #ffffff;
  text-decoration: none;
  background-color: #f64747;
  border-radius: 20px;
  transition: 0.2s;
}

#info-box a:hover {
  transform: scale(1.1);
}


@import '~include-media/dist/_include-media';
@import '../../../Settings.scss';


#chooseAccountType{
    min-height: 600px;
    display: flex;
    flex-wrap: wrap;
    padding: 25px;
    .buttoncontainer{
        flex: 1 1 300px;
        margin-top: 50px;
        text-align: center;
    }
}
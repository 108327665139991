.purplebutton {
  color: #ffffff;
  background-color: #3d2e55;
  font-size: 16px;
  font-weight: 600;
  border-radius: 25px;
}

.page .publicationData {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.page .publicationData > * {
  padding: 1rem;
  margin: 1rem;
}

.page .publicationData > *:first-child {
  padding-left: 0;
  margin-left: 0;
}

.page .publicationData h4 {
  font-size: 1.3rem;
  line-height: 1.1rem;
}

.page .publicationData .parent_badge {
  color: #fff;
  line-height: 3.4rem;
  text-align: center;
  max-height: 5rem;
}

.page .publicationData .parent_badge > b {
  color: #fff;
}


@import '../../../../../node_modules/include-media/dist/include-media';
@import '../../../Settings';

.page {
  .columns.filter-bar {
    background-color: rgba(3,3,3,0.1);
    padding: 0 1rem;
    margin: -0.6rem 0 1rem;
  }

  .select > .input {
    padding-right: 2rem;
  }


    .placeholder{
      color: rgb(192,192,192);
    }
    
    .placeholder > option {
      color: rgba(9,3,3,1);
    }
    
  
  
}




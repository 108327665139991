@import '~include-media/dist/_include-media';
@import '../../../Settings';

.layout .mainColumn .post-card {
  &:hover {
    .image {
      transform: scale(1.1);
    }
  }
  .section {
    position: relative;
    z-index: 1;
  }

  position: relative;
  .actions {
    position: absolute;
    top: 1.25rem;
    right: 0.75rem;
    z-index: 30;

    .tags {
      a.tag:hover {
        text-decoration: none;
      }
    }
  }

  .thumb {
    position: relative;
    width: 100%;
    height: 200px;
    margin: -15px 0 0;
    overflow: hidden;
    z-index: 0;

    .image {
      position: absolute;
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: center;
      transition: $animation-duration;
      background-repeat: no-repeat;
      background-image: url('/assets/fallback-background.jpg');
    }
  }
  h2, p {
    color: $color-black;
  }
  h2 {
    font-size: 1.2rem;
    font-weight: bold;
    margin: 0;
    padding: 0;
  }
}
@import '../../../../../node_modules/include-media/dist/include-media';
@import '../../../Settings';

@import '../../../../../node_modules/bulma/sass/utilities/all';
@import '../../../../../node_modules/bulma/sass/base/all';

@import "../../../../../node_modules/bulma/sass/grid/columns";
@import "../../../../../node_modules/bulma/sass/elements/button";
@import "../../../../../node_modules/bulma/sass/elements/container";
@import "../../../../../node_modules/bulma/sass/elements/content";
@import '../../../../../node_modules/bulma/sass/elements/icon';

@import '../../../../../node_modules/bulma/sass/form/all';

.searchBar {
    position: absolute;
    right: 40px;
    padding: 18px 0;
    z-index: 1;
    @include media('<large') {
        right: 25px;
    }
    @include media('<medium') {
        position: relative;
        right: 0;
        width: 100%;
        border-radius: 0;
        border: none;
        margin: 0;
        padding: 0;
    }
    .field, .field input {
        border-radius: 0 !important;
    }
    .field {
        .button {
            background: rgba(0,0,0,0.8);
            height: 45px;
        }
        .input[type=text] {
            background: rgba(0,0,0,0.8);
            color: #FFF;
            border: 1px rgba(0,0,0,0.8);
            height: 45px;
        }
        input::placeholder {
            color: #FFF;
        }

        @include media('<large') {
            border: 0;
            border-bottom: 2px solid #FFF;
            border-radius: 0;
            width: 100%;

            .input, .button {
                border-radius: 0;
            }
        }
    }
}

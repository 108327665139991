@import '../../../../../node_modules/include-media/dist/include-media';
@import '../../../Settings';

@import '../../../../../node_modules/bulma/sass/utilities/all';
@import '../../../../../node_modules/bulma/sass/base/all';

.post-job-category-input input.input {
  cursor: pointer;
}

.modal-content.post-job-category {
  color: $color-darkgrey;
  overflow-x: hidden;
  @include media('<large') {
    width: 85%;
  }
  @include media('<small') {
    width: 100%;
  }
  .category-list {
    padding: 2rem 0 0.5rem;
  }
}
@import '~reset-css/reset';
@import '~@fortawesome/fontawesome-free/css/all';
@import '~include-media/dist/_include-media';
@import './Settings';

* {
    box-sizing: inherit;
}

b {
    font-weight: bold;
}

.icon::before {
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
  }

body {
    font-family: Roboto,Tahoma,Geneva,Helvetica,sans-serif;
    font-size: 14px;
}

select, input, button, textarea{
    border: none;
    background: #fff;
}

button, label, a{
    cursor: pointer;
}

button{
    border: none;
}

textarea{
    padding: 10px;
}

.btn-retour{
    margin: 32px 0px;
    a{
        color: #000000;
        text-decoration: none;
        text-transform: uppercase;
        font-weight: 600;
    }
}

.btn-retour a:before{
    content: "<";
    color: white;
    background-color: #367bf0;
    padding: 7px 15px;
    border-radius: 200px;
    font-size: 22px;
    margin: 0px 18px;
    font-weight: 200;
}

img.fallback {
    background: url('../img/fallback.svg') no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    min-height: 200px;
}

.accountbanner{
    background-color: $color-purple;
    color: #ffffff;
    padding: 25px;
    display: flex;
    flex-wrap: wrap;
    margin-left: calc(0% - 40px);
    width: calc(100% + 30px);
    box-sizing: content-box;
    i {
        margin-right: 15px;
    }
    @include media('<medium') {
        margin-left: calc(0% - 20px);
        width: calc(98%);
    }
    h2{
        text-transform: uppercase;
        font-size: 22px;
        font-weight: 600;
    }
    span{
        font-weight: 400;
        font-size: 16px;
        margin-left: 6px;
    }
    .fa-user{
        margin-right: 6px;
    }
}
.content {
    h2{
        font-size: 1.5rem;
        margin: 12px 0px;
        background: $color-black;
        color: #fff;
        font-weight: 700;
        position: relative;
        height: 3rem;
        margin-bottom: 2rem;
        line-height: 3rem;
        vertical-align: middle;
        border-left: 3rem solid $color-turquoise;
        padding-left: 1rem;
    }
    h3 {
        font-size: 1.8rem;
        line-height: 1.3em;
        font-weight: 700;
    }
}

.fas.fa-heart {
    color: $color-red !important;
 }
.far.fa-heart:hover {
    color: $color-red !important;
}

strong {
    font-weight: bold;
    color: inherit;
}

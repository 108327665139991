@import '~include-media/dist/_include-media';
@import '../../Settings';

.stripe {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px;
    @include media('>=large') {
        transition: $animation-duration;
        &:hover {
            padding-left: 80px;
        }
    }
    @include media('<medium') {
        flex-direction: column;
        align-items: flex-start;
        padding: 40px 20px;
    }
    .gifLoader{
        position: absolute;
        max-height: 100px;
        opacity: 0;
        display: none;
    }
    .icon {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: #ffffff;
        @include media('<medium') {
            flex-direction: row;
        }

        .imgIcon {
            width: 100px;
            height: 100px;
            margin-bottom: 20px;
            @include media('<medium') {
                width: 80px;
                height: 80px;
                margin: 0 20px 0 0;
            }
        }
    }
    .icon.loading{
        opacity: 0.7;
        .gifLoader{
            display: block;
            width: 80px;
            animation: tileLoading 1s infinite;
            animation-delay: 0.3s;
        }
    }
    h3 {
        color: #fff;
        font-size: 2rem;
        @include media('<medium') {
            font-size: 1.8rem;
        }
    }
    .menu {
        padding: 40px;
        margin-left: 40px;
        &:hover {
            li {
                opacity: 0.5;
            }
        }
        @include media('>=large') {
            flex: 1;
        }
        @include media('>=medium') {
            border-left: 1px rgba(0, 0, 0, 0.1) solid;
        }
        @include media('<medium') {
            padding: 40px 0 0;
            margin-left: 0;
        }
        li {
            transition: $animation-duration;
            &:last-of-type {
                margin-bottom: 0;
            }
            &:hover {
                opacity: 1;
            }
            a {
                // display: block;
                font-size: 18px;
                text-decoration: none;
                color: #ffffff;
                margin-bottom: 20px;
                @include media('<medium') {
                    font-size: 14px;
                }
                display: flex;
                align-items: center;

                span {
                    padding-left: 0.5rem;
                }
            }
        }
        .loading{
            opacity: 0.7;
            .gifLoader{
                display: block;
                width: 50px;
                animation: tileLoading 1s infinite;
                animation-delay: 0.3s;
            }
        }
    }
    .thumb {
        width: 200px;
        height: 200px;
        border-radius: 100px;
        overflow: hidden;
        img {
            width: 100%;
            height: 100%;
        }
        @include media('<large') {
            display: none;
        }
    }
    @keyframes tileLoading {
        from { opacity: 0.6; }
        to { opacity: 0.6; }
    }
}
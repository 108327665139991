.purplebutton {
  color: #ffffff;
  background-color: #3d2e55;
  font-size: 16px;
  font-weight: 600;
  border-radius: 25px;
}

.searchResults .searchBox {
  margin: 2rem 0;
}

.searchResults .searchBox .itemList {
  display: flex;
  flex-wrap: wrap;
  margin: 20px;
}

.searchResults .searchBox .contentItem,
.searchResults .searchBox .article {
  display: block;
  text-decoration: none;
}

@media (min-width: 1024px) {
  .searchResults .searchBox .contentItem,
  .searchResults .searchBox .article {
    width: calc((100% / 3) - ((40px * (3 - 1)) / 3));
    margin: 0 40px 40px 0;
  }

  .searchResults .searchBox .contentItem:nth-of-type(3n),
  .searchResults .searchBox .article:nth-of-type(3n) {
    margin-right: 0;
  }
}

@media (max-width: 1023px) {
  .searchResults .searchBox .contentItem,
  .searchResults .searchBox .article {
    width: calc((100% / 2) - ((20px * (2 - 1)) / 2));
    margin: 0 20px 20px 0;
    margin: 0 20px 40px 0;
  }

  .searchResults .searchBox .contentItem:nth-of-type(2n),
  .searchResults .searchBox .article:nth-of-type(2n) {
    margin-right: 0;
  }
}

@media (max-width: 567px) {
  .searchResults .searchBox .contentItem,
  .searchResults .searchBox .article {
    width: calc((100% / 1) - ((20px * (1 - 1)) / 1));
    margin: 0 20px 20px 0;
    margin: 0 20px 40px 0;
  }

  .searchResults .searchBox .contentItem:nth-of-type(1n),
  .searchResults .searchBox .article:nth-of-type(1n) {
    margin-right: 0;
  }
}

.searchResults .searchBox .contentItem:hover .image,
.searchResults .searchBox .article:hover .image {
  transform: scale(1.1);
}

.searchResults .searchBox .contentItem .section,
.searchResults .searchBox .article .section {
  position: relative;
  display: inline-block;
  padding: 8px 20px;
  margin-left: 20px;
  color: #ffffff;
  z-index: 1;
}

.searchResults .searchBox .contentItem .thumb,
.searchResults .searchBox .article .thumb {
  position: relative;
  width: 100%;
  height: 200px;
  margin: -15px 0 20px;
  overflow: hidden;
  z-index: 0;
}

.searchResults .searchBox .contentItem .thumb .image,
.searchResults .searchBox .article .thumb .image {
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  transition: 0.2s;
  background-repeat: no-repeat;
  background-image: url("/assets/fallback-background.jpg");
}

.searchResults .searchBox .contentItem h2,
.searchResults .searchBox .contentItem p,
.searchResults .searchBox .article h2,
.searchResults .searchBox .article p {
  padding: 0 20px;
}

.searchResults .searchBox .contentItem h2,
.searchResults .searchBox .article h2 {
  margin-bottom: 10px;
  font-size: 18px;
  color: #000000;
}

.searchResults .searchBox .contentItem p,
.searchResults .searchBox .article p {
  color: #333;
}


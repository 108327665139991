@import '../../../Settings';

.contentText {
    margin-bottom: 32px;

    p {
        line-height: 22px;
        margin: 10px 0px;
    }
}

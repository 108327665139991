@import '~include-media/dist/_include-media';
@import '../../../Settings';

.region-map {
    max-width: 45rem;
    position: relative;
    margin: 10px auto;
    @include media('<small') {
        margin: 10px -1.5rem;
    }

    .button.outside {
        position: absolute;
        bottom: 2%;
        right: -33%;

        @include media('<small') {
            bottom: -20%;
            right: 0;
            font-size: 0.7rem;
        }
    }

    .button.outsideLeft {
        position: absolute;
        bottom: 2%;
        right: -15%;

        @include media('<small') {
            bottom:-10%;
            right: 0;
            font-size: 0.7rem;
        }
    }
}

#MapPart1, #MapPart2, #MapPart3, #MapPart4, #MapPart5, #MapPart6, #MapPart7, #MapPart8{
    fill: #faa84b;
    cursor: pointer;
    transition: 0.5s ease;
}

#MapPart1:hover, #MapPart2:hover, #MapPart3:hover, #MapPart4:hover, #MapPart5:hover, #MapPart6:hover, #MapPart7:hover, #MapPart8:hover{
    fill: #f3702a;
    transition: 0.5s ease;
}

.cls-2{
    fill: #f3702a;
}

path{
    fill: #000000;
}
@import '~include-media/dist/_include-media';
@import '../../Settings';

// jobs (?)
.searchResults {
    .searchBox {
        margin: 2rem 0;
        .itemList {
            display: flex;
            flex-wrap: wrap;
            margin: 20px;
        }

        .contentItem, .article {
            display: block;
            text-decoration: none;
            @include media('>=large') {
                @include columns(3, 40px);
            }
            @include media('<large') {
                @include columns(2, 20px);
                margin: 0 20px 40px 0;
            }
            @include media('<small') {
                @include columns(1, 20px);
                margin: 0 20px 40px 0;
            }
            &:hover {
                .image {
                    transform: scale(1.1);
                }
            }
            .section {
                position: relative;
                display: inline-block;
                padding: 8px 20px;
                margin-left: 20px;
                color: #ffffff;
                z-index: 1;
            }
            .thumb {
                position: relative;
                width: 100%;
                height: 200px;
                margin: -15px 0 20px;
                overflow: hidden;
                z-index: 0;
                .image {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    background-size: cover;
                    background-position: center;
                    transition: $animation-duration;
                    background-repeat: no-repeat;
                    background-image: url('/assets/fallback-background.jpg');
                }
            }
            h2, p {
                padding: 0 20px;
            }
            h2 {
                margin-bottom: 10px;
                font-size: 18px;
                color: #000000;
            }
            p {
                color: $color-black;
            }
        }
    }
}
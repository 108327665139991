@import '~include-media/dist/_include-media';
@import '../../Settings.scss';

.page {
  .publicationData {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    > * {
      padding: 1rem;
      margin: 1rem;

      &:first-child {
        padding-left: 0;
        margin-left: 0;
      }
    }
    h4 {
      font-size: 1.3rem;
      line-height: 1.1rem;
    }
    .parent_badge {
      color: #fff;
      line-height: 3.4rem;
      text-align: center;
      max-height: 5rem;

      > b {
        color: #fff;
      }
    }
  }
}
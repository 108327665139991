@import '../../../../node_modules/include-media/dist/include-media';
@import '../../Settings';

@import '~bulma/sass/utilities/_all.sass';
@import '~bulma/sass/base/_all.sass';

@import "~bulma/sass/grid/columns.sass";
@import "~bulma/sass/elements/button.sass";
@import "~bulma/sass/elements/container.sass";
@import "~bulma/sass/elements/content.sass";
@import '~bulma/sass/elements/icon.sass';

@import '~bulma/sass/form/_all.sass';

.login-page {
  form {
    background-color: rgba(3,3,3,0.1);
    padding: 0 1rem;
    margin: -0.6rem 0 1rem;

    div.column:not(:last-child) {
      padding-bottom: 0;
      margin-bottom: 0;
    }
  }

  div.registration-hero {
    h2 {
      font-size: 2rem;
      font-weight: 800;
      margin: 0;
    }

    p {
      //margin: 1rem 0;

      span {
        font-style: italic;
      }
    }
    ul {
      margin: 0.5rem;

      li {
        padding: 0.2rem 0;
      }
    }

    .fa-check {
      margin-right: 6px;
    }
  }
}

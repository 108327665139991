// BREAKPOINTS
$breakpoints: (small: 568px, medium: 768px, large: 1024px, xlarge: 1280px, xxlarge: 1440px);

// COLORS
$color-black: #333;
$color-red: #f64747;
$color-blue: #367bf0;
$color-purple: #3d2e55;
$color-green: #26bcc2;
$color-yellow: #ffc831; 
$color-orange: #f56b2a;
$color-smoke: #f9f9f9;
$color-midgray: #818181;
$color-lightgrey: #e5e5e5;
$color-darkgrey: #545454;
$color-turquoise: #00b6bf;
$color-deep-blue: #175a9f;

// VARIABLES
$height-header: 100px;
$border-radius: 10px;
$animation-duration: 0.2s;

// MIXINS
@mixin columns($column, $margin) {
    width: calc((100% / #{$column}) - ((#{$margin} * (#{$column} - 1)) / #{$column}));
    margin: 0 $margin $margin 0;
    &:nth-of-type(#{$column}n) {
        margin-right: 0;
    }
}

// BUTTONS
.purplebutton{
    color: #ffffff;
    background-color: $color-purple;
    font-size: 16px;
    font-weight: 600;
    border-radius: 25px
}
.purplebutton {
  color: #ffffff;
  background-color: #3d2e55;
  font-size: 16px;
  font-weight: 600;
  border-radius: 25px;
}

.breadcrumbs {
  position: absolute;
  left: 40px;
  right: 300px;
  line-height: 44px;
  margin: 18px 0;
  display: flex;
  align-items: center;
  order: 1;
  z-index: 1;
}

.breadcrumbs.wo-searchBar {
  right: 40px;
}

.breadcrumbs > div {
  overflow: hidden;
  padding: 0 15px;
  background-color: #545454;
}

@media (max-width: 1023px) {
  .breadcrumbs {
    left: 25px;
    right: 290px;
  }
}

@media (max-width: 767px) {
  .breadcrumbs {
    position: sticky;
    top: 0;
    margin: 0 0 2px 0;
    z-index: 5;
  }

  .breadcrumbs > a:nth-last-child(1n+3) {
    display: none;
  }
}

.breadcrumbs a,
.breadcrumbs span {
  white-space: nowrap;
  font-size: 0.8em;
  color: #818181;
}

.breadcrumbs a:hover,
.breadcrumbs a:last-child,
.breadcrumbs span:hover,
.breadcrumbs span:last-child {
  color: #fff;
}

.breadcrumbs a:before,
.breadcrumbs span:before {
  content: '\25b6';
  margin: 0 10px;
}

.breadcrumbs a:first-child:before,
.breadcrumbs span:first-child:before {
  display: none;
}

.breadcrumbs a .gifLoader,
.breadcrumbs span .gifLoader {
  display: none !important;
}

.breadcrumbs a.loading,
.breadcrumbs span.loading {
  opacity: 0.7;
}


.purplebutton {
  color: #ffffff;
  background-color: #3d2e55;
  font-size: 16px;
  font-weight: 600;
  border-radius: 25px;
}

.createaccountbody span.error {
  color: #ff0000;
}

.accountbanner {
  background-color: #3d2e55;
  color: #ffffff;
  padding: 25px;
  display: flex;
  flex-wrap: wrap;
  margin-left: calc(0% - 40px);
  width: calc(100% + 30px);
  box-sizing: content-box;
}

.accountbanner i {
  margin-right: 15px;
}

@media (max-width: 767px) {
  .accountbanner {
    margin-left: calc(0% - 20px);
    width: calc(98%);
  }
}

.accountbanner h2 {
  text-transform: uppercase;
  font-size: 22px;
  font-weight: 600;
}

.accountbanner span {
  font-weight: 400;
  font-size: 16px;
  margin-left: 6px;
}

.accountbanner .fa-user {
  margin-right: 6px;
}


@import '../../../../../node_modules/@glidejs/glide/src/assets/sass/glide.core';
@import '../../../../../node_modules/include-media/dist/include-media';
@import '../../../Settings';

@import '~bulma/sass/utilities/_all.sass';
@import '~bulma/sass/base/_all.sass';

@import "~bulma/sass/grid/columns.sass";
@import "~bulma/sass/elements/container.sass";
@import "~bulma/sass/elements/content.sass";

.mtiles {
    margin-bottom: 0 !important;

    @include media('>large') {
        position: absolute;
        top: 400px;
        padding: 2.5rem
    }
    .mtile {
        color: #fff;

        @include media('<large') {
            padding-top: 0 !important;
            padding-bottom: 0 !important;
        }

        .mtile-container {
            height: 10rem;
            cursor: pointer;
            border-radius: $border-radius;
            transition: $animation-duration;
            display: flex;
            align-items: center;

            @include media('<large') {
                border-radius: 0;
            }

            &:hover {
                color: #ffffff;
                transform: scale(1.1);
                box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.2);
            }

            padding: 2rem;
            text-align: center;

            > * {
                margin: auto;
            }

            &.iconOnly {
                display: flex;
                padding: 0;

                .icon {
                    width: auto;
                    height: auto;
                    max-width: 9rem;
                    max-height: 9rem;
                }
            }
        }
    }
}

#glide {
    display: flex;
    flex-direction: column;
    position: relative;
    min-height: calc(120vh - #{$height-header});
    padding-top: 210px;
    box-sizing: border-box;
    background-size: cover;
    background-position: center;
    background-color: #f5f5f5;
    &.withBreadcrumb {
        padding-top: 290px;
        @include media('<large') {
            padding-top: 270px;
        }
        .glide__overlay {
            padding: 120px 40px 0;

            @include media('<large') {
                padding: 0 20px 0;
            }
        }
    }

    @include media('<large') {
        min-height: calc(75vh - #{$height-header});
        max-height: calc(75vh - #{$height-header});
    }
    .glide__track {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 0;
        .glide__slides {
            height: 100%;
            .glide__slide {
                background-image: url('/assets/fallback-background.jpg');

                background-size: cover;
                background-position: center;

                position: relative;
            }
        }
    }
    @include media('<large') {
      height: 70%;
    }
    .glide__overlay {
        @include media('<large') {
            padding: 0;
            position: absolute;
            top: 0;
            width: 100%;
            margin: 0;
        }
        a {
            display: block;
            margin: 80px 0 0 40px;
            width: 660px;
            height: 200px;
            padding: 40px;
            color: #ffffff;
            text-decoration: none;
            background-color: rgba(0, 0, 0, 0.5);
            overflow: hidden;

            @include media('<large') {
                left: 0;

                min-width: 0;
                max-width: none;
                margin-left: 0;
                padding: 1.5rem;
                width: 100%;
            }

            .glide__title {
                margin-bottom: 0;
                font-size: 28px;
                font-weight: 700;
            }
        }
    }
    .glide__bullets {
        position: absolute;
        bottom: 1.5rem;
        right: 1rem;
        display: flex;
        padding: 10px 40px;
        z-index: 15;
        @include media('<large') {
            padding: 20px;
            //bottom: 30%;
            right: 0.5rem;
        }
        .glide__bullet {
            width: 1rem;
            height: 1rem;
            margin-right: 5px;
            padding: 0;
            border-radius: 100%;
            outline: none;
            border: 3px rgba(0,0,0,0.6) solid;
            background-color: grey;
            &.glide__bullet--active {
                background-color: #fff;
            }
        }
    }
}

.loading {
    #glide {
        .glide__slide {
            background-image: url(https://img.generation-nt.com/fake_016A011E01646413.jpg) !important;
        }
        a {
            color: #cccccc;
            animation: textBlink 1.5s infinite;
            .glide__title {
                background-color: #cccccc;
                animation: BackgroundBlink 1.5s infinite;
            }
            .glide__excerpt {
                background-color: #cccccc;
                animation: BackgroundBlink 1.5s infinite;
            }
        }
    }
    @keyframes textBlink {
        0% {
            background-color: #cccccc;
        }
        50% {
            background-color: #dddddd;
        }
        100% {
            background-color: #cccccc;
        }
    }
    @keyframes BackgroundBlink {
        0% {
            background-color: #cccccc;
        }
        50% {
            background-color: #dddddd;
        }
        100% {
            background-color: #cccccc;
        }
    }
}

.purplebutton {
  color: #ffffff;
  background-color: #3d2e55;
  font-size: 16px;
  font-weight: 600;
  border-radius: 25px;
}

#chooseAccountType {
  min-height: 600px;
  display: flex;
  flex-wrap: wrap;
  padding: 25px;
}

#chooseAccountType .buttoncontainer {
  flex: 1 1 300px;
  margin-top: 50px;
  text-align: center;
}


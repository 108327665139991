@import '../../../../../node_modules/include-media/dist/include-media';
@import '../../../Settings';

@import '~bulma/sass/utilities/_all.sass';
@import '~bulma/sass/base/_all.sass';

@import "~bulma/sass/elements/notification.sass";

.layout {
    position: relative;
    padding: 120px 40px 0;
    box-sizing: border-box;
    z-index: 0;

    @include media('<large') {
        padding: 40px 25px 0;
    }
    @include media('<medium') {
        padding: 40px 10px 0;
    }

    .background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 50%;
        background-image: url('../../../../img/fallback-background.jpg');
        background-size: cover;
        background-position: center;
        filter: grayscale(100%);
        z-index: 0;
        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: linear-gradient(to top, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 50%);
        }
    }
    .mainColumn {
        position: relative;
        max-width: map-get($breakpoints, xlarge);
        margin: 0 auto;
        background: #ffffff;
        border-bottom: 1px $color-smoke solid;
        z-index: 1;
        padding: 40px;

        &.withSideBar {
            padding: 0;
        }
        &.withoutTitle {
            padding-top: 0;
        }

        @include media('<large') {
            margin-top: 40px;
        }
        @include media('<medium') {
            padding: 20px;
            padding-bottom: 80px;
        }

        h1, h2, h3 {
            font-weight: 700;
            color: $color-black;
        }
        h1 {
            font-size: 3rem;

            padding-bottom: 1rem;
            margin-bottom: 2rem;

            position: relative;
            &:after {
                content: '';
                position: absolute;
                left: 0;
                bottom: 0;
                display: block;
                width: 100px;
                border-bottom: 1px $color-black solid;
            }

            @include media('<large') {
                font-size: 2rem;
                line-height: 2rem;
            }

            .action {
                margin: 0;
                float: right;

                @include media('<small') {
                    &:not(.action-mini) {
                        display: block;
                        margin-top: 1rem;
                        float: none;
                    }
                }
            }
        }

        h2 {
            font-size: 2rem;

            padding-bottom: 1rem;
            margin-bottom: 1rem;
        }

        h3 {
            font-size: 1.5rem;

            padding-bottom: 1rem;
        }
    }

    .post-action {
        margin-top: 1.2rem;
        margin-left: 1rem;
        float: right;

        &.buttons {
            @include media('<small') {
                float: none;
            }
        }
    }

    b {
        font-weight: bold;
    }
}
.preview {
    display: none;
}

@import '~include-media/dist/_include-media';
@import '../../Settings';

.breadcrumbs {
    position: absolute;
    left: 40px;
    right: 300px;
    line-height: 44px;
    margin: 18px 0;
    display: flex;
    align-items: center;

    &.wo-searchBar {
        right: 40px;
    }

    > div {
        overflow: hidden;

        padding: 0 15px;
        background-color: $color-darkgrey;
    }

    order: 1;

    z-index: 1;

    @include media('<large') {
        left: 25px;
        right: 290px;
    }

    @include media('<medium') {
        position: sticky;
        top: 0;
        margin: 0 0 2px 0;
        z-index: 5;

        > a:nth-last-child(1n+3) {
            display: none;
        }
    }

    a, span {
        white-space: nowrap;
        font-size: 0.8em;
        color: $color-midgray;
        &:hover, &:last-child {
            color: #fff;
        }
        &:before {
            content: '\25b6';
            margin: 0 10px;
        }
        &:first-child:before {
            display: none;
        }

        .gifLoader{
            display: none !important;
        }
        &.loading {
            opacity: 0.7;
        }
    }
}
.purplebutton {
  color: #ffffff;
  background-color: #3d2e55;
  font-size: 16px;
  font-weight: 600;
  border-radius: 25px;
}

.partners-list {
  text-align: center;
  padding: 0 40px;
}

@media (max-width: 767px) {
  .partners-list {
    padding: 0 20px;
  }
}

@media (max-width: 567px) {
  .partners-list {
    flex-direction: column;
    align-items: center;
  }
}

.partners-list img {
  max-width: 165px;
  max-height: 100px;
  padding-left: 20px;
  padding-right: 20px;
}


@import url(../../../../../node_modules/@fortawesome/fontawesome-free/css/all.css);

.purplebutton {
  color: #ffffff;
  background-color: #3d2e55;
  font-size: 16px;
  font-weight: 600;
  border-radius: 25px;
}

.contentFlora .box.media .content strong {
  display: block;
  margin-bottom: 10px;
}

.contentFlora .box.media .content .wIcon {
  padding-left: 25px;
  margin-bottom: 5px;
}

.contentFlora .box.media .content .wIcon > i {
  margin-left: -25px;
  margin-right: 5px;
}


@import '../../../../../node_modules/include-media/dist/include-media';
@import '../../../Settings';

#footer {
    padding-top: 40px;
    .partners {
        display: flex;

        align-items: center;
        justify-content: center;

        flex-wrap: wrap;
        padding: 0 40px;
        @include media('<medium') {
            padding: 0 20px;
        }
        @include media('<small') {
            flex-direction: column;
            align-items: center;
        }
        a {
            margin: 0 40px 40px 0;
            &:last-child {
                margin-right: 0;
            }
            @include media('<small') {
                margin: 0 0 40px 0;
            }
            img {
                height: 80px;
            }
        }
    }
    .links {
        display: flex;
        justify-content: center;
        padding: 0 40px;
        margin-bottom: 10px;
        @include media('<large') {
            flex-direction: column;
            align-items: center;
        }
        li {
            flex: none;
            @include media('>=large') {
                &:after {
                    content: '-';
                    margin: 0 10px;
                }
                &:last-child:after {
                    content: none;
                }
            }
            a {
                font-weight: bold;
                display: inline-block;
                font-size: 13px;
                color: #000000;
                text-decoration: none;
                @include media('<large') {
                    margin-bottom: 10px;
                    text-align: center;
                }
            }
        }
    }
    .socials {
        @include media('<xlarge') {
            margin-right: auto;
        }
        @include media('>=xlarge') {
            display: none;
        }
    }
}
#rgpd {
    padding: 20px;
    background-color: #333333;
    p {
        padding: 0 40px;
        color: #ffffff;
        text-align: center;
    }
}
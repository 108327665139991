.purplebutton {
  color: #ffffff;
  background-color: #3d2e55;
  font-size: 16px;
  font-weight: 600;
  border-radius: 25px;
}

.contentText {
  margin-bottom: 32px;
}

.contentText p {
  line-height: 22px;
  margin: 10px 0px;
}


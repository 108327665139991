.purplebutton {
  color: #ffffff;
  background-color: #3d2e55;
  font-size: 16px;
  font-weight: 600;
  border-radius: 25px;
}

.avatar-input .image {
  position: relative;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 769px) {
  .avatar-input .image {
    height: 64px;
    width: 64px;
  }
}

.avatar-input .image input[type="file"] {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  white-space: nowrap;
  width: 1px;
}

.avatar-input .image label {
  position: absolute;
  bottom: -0.5rem;
  right: -0.5rem;
  cursor: pointer;
  padding: 5px;
  text-shadow: 0 0 3px #333;
  opacity: 0.8;
  color: #fff;
}

.avatar-input .image label:hover {
  opacity: 1;
}


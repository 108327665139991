.purplebutton {
  color: #ffffff;
  background-color: #3d2e55;
  font-size: 16px;
  font-weight: 600;
  border-radius: 25px;
}

.header {
  display: flex;
  height: 100px;
  z-index: 1;
}

@media (max-width: 1023px) {
  .header .socials {
    display: none;
  }
}

@media (max-width: 1023px) {
  .header.active {
    margin-bottom: 100px;
  }

  .header.active .right .menu {
    display: block;
    padding: 0;
  }
}

@media (max-width: 767px) {
  .header.active {
    margin-bottom: 200px;
  }
}

.header .left,
.header .right {
  display: flex;
  align-items: center;
}

.header .left {
  max-width: 45%;
  padding-left: 40px;
}

@media (max-width: 1023px) {
  .header .left {
    padding-left: 20px;
    max-width: 100%;
  }
}

.header .left .logo {
  height: 100%;
  margin-right: 40px;
  display: flex;
  align-items: center;
}

@media (max-width: 1023px) {
  .header .left .logo {
    margin-right: 20px;
  }
}

.header .left .logo img {
  max-height: 60px;
}

.header .left .category {
  height: 40px;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header .right {
  flex: 1;
  justify-content: flex-end;
}

.header .right .menu {
  display: flex;
  align-items: center;
  margin-right: 40px;
}

@media (max-width: 1023px) {
  .header .right .menu {
    display: none;
    justify-content: flex-end;
    position: absolute;
    top: 100px;
    right: 0;
    left: 0;
    min-height: 100px;
    padding: 0 40px;
    margin-right: auto;
    background: #f64747;
    z-index: 1;
  }
}

.header .right .menu li {
  text-align: center;
  margin-right: 40px;
}

.header .right .menu li:last-of-type {
  margin-right: 0;
}

@media (max-width: 1023px) {
  .header .right .menu li {
    float: left;
    margin: 0;
    width: 25%;
    height: 99px;
    padding: 2rem 0;
  }

  .header .right .menu li.double {
    width: 50%;
  }
}

@media (max-width: 767px) {
  .header .right .menu li {
    width: 50%;
  }

  .header .right .menu li.double {
    width: 100%;
  }
}

.header .right .menu li a,
.header .right .menu li b {
  color: #fff;
}

@media (min-width: 1024px) {
  .header .right .menu li a {
    color: #333;
    transition: 0.2s;
  }

  .header .right .menu li a:hover {
    color: #f64747;
  }

  .header .right .menu li a:hover svg {
    color: #f64747;
  }
}

.header .right .menu li a svg {
  display: block;
  margin: 0 auto 10px;
  font-size: 28px;
  color: #ffffff;
}

@media (min-width: 1024px) {
  .header .right .menu li a svg {
    color: #333;
    transition: 0.2s;
  }
}

.header .right .toggleMenu {
  margin-right: 40px;
  color: #f64747;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
}

@media (min-width: 1024px) {
  .header .right .toggleMenu {
    display: none;
  }
}

@media (max-width: 1023px) {
  .header .right .toggleMenu {
    margin-right: 20px;
  }
}

.header .right .toggleMenu svg {
  display: block;
  margin: 0 auto 10px;
  font-size: 28px;
  transition: 0.2s;
}

.header .gifLoader {
  position: absolute;
  display: none;
  width: 60px;
  opacity: 0;
}

.header .loading {
  opacity: 0.7;
}

.header .loading .gifLoader {
  display: block;
  animation: tileLoading 1s infinite;
  animation-delay: 0.3s;
}

@keyframes tileLoading {
  from {
    opacity: 0.6;
  }

  to {
    opacity: 0.6;
  }
}


.purplebutton {
  color: #ffffff;
  background-color: #3d2e55;
  font-size: 16px;
  font-weight: 600;
  border-radius: 25px;
}

.stripe {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px;
}

@media (min-width: 1024px) {
  .stripe {
    transition: 0.2s;
  }

  .stripe:hover {
    padding-left: 80px;
  }
}

@media (max-width: 767px) {
  .stripe {
    flex-direction: column;
    align-items: flex-start;
    padding: 40px 20px;
  }
}

.stripe .gifLoader {
  position: absolute;
  max-height: 100px;
  opacity: 0;
  display: none;
}

.stripe .icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #ffffff;
}

@media (max-width: 767px) {
  .stripe .icon {
    flex-direction: row;
  }
}

.stripe .icon .imgIcon {
  width: 100px;
  height: 100px;
  margin-bottom: 20px;
}

@media (max-width: 767px) {
  .stripe .icon .imgIcon {
    width: 80px;
    height: 80px;
    margin: 0 20px 0 0;
  }
}

.stripe .icon.loading {
  opacity: 0.7;
}

.stripe .icon.loading .gifLoader {
  display: block;
  width: 80px;
  animation: tileLoading 1s infinite;
  animation-delay: 0.3s;
}

.stripe h3 {
  color: #fff;
  font-size: 2rem;
}

@media (max-width: 767px) {
  .stripe h3 {
    font-size: 1.8rem;
  }
}

.stripe .menu {
  padding: 40px;
  margin-left: 40px;
}

.stripe .menu:hover li {
  opacity: 0.5;
}

@media (min-width: 1024px) {
  .stripe .menu {
    flex: 1;
  }
}

@media (min-width: 768px) {
  .stripe .menu {
    border-left: 1px rgba(0, 0, 0, 0.1) solid;
  }
}

@media (max-width: 767px) {
  .stripe .menu {
    padding: 40px 0 0;
    margin-left: 0;
  }
}

.stripe .menu li {
  transition: 0.2s;
}

.stripe .menu li:last-of-type {
  margin-bottom: 0;
}

.stripe .menu li:hover {
  opacity: 1;
}

.stripe .menu li a {
  font-size: 18px;
  text-decoration: none;
  color: #ffffff;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

@media (max-width: 767px) {
  .stripe .menu li a {
    font-size: 14px;
  }
}

.stripe .menu li a span {
  padding-left: 0.5rem;
}

.stripe .menu .loading {
  opacity: 0.7;
}

.stripe .menu .loading .gifLoader {
  display: block;
  width: 50px;
  animation: tileLoading 1s infinite;
  animation-delay: 0.3s;
}

.stripe .thumb {
  width: 200px;
  height: 200px;
  border-radius: 100px;
  overflow: hidden;
}

.stripe .thumb img {
  width: 100%;
  height: 100%;
}

@media (max-width: 1023px) {
  .stripe .thumb {
    display: none;
  }
}

@keyframes tileLoading {
  from {
    opacity: 0.6;
  }

  to {
    opacity: 0.6;
  }
}


@import '../../../../node_modules/include-media/dist/include-media';
@import '../../Settings';

@import '../../../../node_modules/bulma/sass/utilities/all';
@import '../../../../node_modules/bulma/sass/base/all';

.category-list {
  > div {
    margin-bottom: 1rem;
    padding: 0 1rem;

    &:first-child {
      margin-bottom: 1.5rem;
    }
  }
}

.category-title, .subcategory-title {
  display: block;
  cursor: pointer;
  transition: color, background 0.5s;

  &.selected, &:hover {
    color: darken($color-turquoise, 10%);
    background: rgba($color-turquoise, 0.1);

  }
}
.category-title {
  padding: 1rem;
  border: 1px solid rgba(0,0,0,0.08);
  font-size: 2em;
  font-weight: bold;
}

.subcategory-title {
  padding: 0.5rem;
  border: 1px solid rgba(0,0,0,0.05);
}
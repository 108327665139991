.purplebutton {
  color: #ffffff;
  background-color: #3d2e55;
  font-size: 16px;
  font-weight: 600;
  border-radius: 25px;
}

.page .columns.filter-bar {
  background-color: rgba(3, 3, 3, 0.1);
  padding: 0 1rem;
  margin: -0.6rem 0 1rem;
}

.page .select > .input {
  padding-right: 2rem;
}

.page .placeholder {
  color: silver;
}

.page .placeholder > option {
  color: #090303;
}


@import '../../../Settings';
@import '../../../../../node_modules/@fortawesome/fontawesome-free/css/all.css';

.contentFlora{
    .box.media {
        .content {
            strong {
                display: block;
                margin-bottom: 10px;
            }

            .wIcon {
                > i {
                    margin-left: -25px;
                    margin-right: 5px;
                }

                padding-left: 25px;
                margin-bottom: 5px;
            }
        }
    }
}
.purplebutton {
  color: #ffffff;
  background-color: #3d2e55;
  font-size: 16px;
  font-weight: 600;
  border-radius: 25px;
}

#footer {
  padding-top: 40px;
}

#footer .partners {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0 40px;
}

@media (max-width: 767px) {
  #footer .partners {
    padding: 0 20px;
  }
}

@media (max-width: 567px) {
  #footer .partners {
    flex-direction: column;
    align-items: center;
  }
}

#footer .partners a {
  margin: 0 40px 40px 0;
}

#footer .partners a:last-child {
  margin-right: 0;
}

@media (max-width: 567px) {
  #footer .partners a {
    margin: 0 0 40px 0;
  }
}

#footer .partners a img {
  height: 80px;
}

#footer .links {
  display: flex;
  justify-content: center;
  padding: 0 40px;
  margin-bottom: 10px;
}

@media (max-width: 1023px) {
  #footer .links {
    flex-direction: column;
    align-items: center;
  }
}

#footer .links li {
  flex: none;
}

@media (min-width: 1024px) {
  #footer .links li:after {
    content: '-';
    margin: 0 10px;
  }

  #footer .links li:last-child:after {
    content: none;
  }
}

#footer .links li a {
  font-weight: bold;
  display: inline-block;
  font-size: 13px;
  color: #000000;
  text-decoration: none;
}

@media (max-width: 1023px) {
  #footer .links li a {
    margin-bottom: 10px;
    text-align: center;
  }
}

@media (max-width: 1279px) {
  #footer .socials {
    margin-right: auto;
  }
}

@media (min-width: 1280px) {
  #footer .socials {
    display: none;
  }
}

#rgpd {
  padding: 20px;
  background-color: #333333;
}

#rgpd p {
  padding: 0 40px;
  color: #ffffff;
  text-align: center;
}

